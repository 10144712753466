import '../sass/frontend.scss';
import Popup from './parts/popup-window';
import { initSwipers } from './components/swiper-init';
import { debounce, anchorLinkScroll } from './parts/helpers';
(function ($) {
    function ready() {
        const popupInstance = new Popup();
        popupInstance.init();
        initSwipers();
        const dummyLink = document.querySelectorAll('li.dummy-link>a');
        const blogSearch = document.querySelector('.js-blog-search');
        const header = document.querySelector('#site-header');
        const openSubMenu = document.querySelectorAll('.mobile_menu .dummy-link>a');
        const phoneInputs = document.querySelectorAll('.phone_input');
        setTimeout(() => {
            const countryList = document.querySelectorAll('.iti__country-list');
            if (!countryList)
                return;
            $(countryList).mCustomScrollbar();
            countryList.forEach(item => {
                const listItem = item.querySelectorAll('li.iti__country');
                if (!listItem)
                    return;
                listItem.forEach(itemList => {
                    const element = itemList;
                    const countryCode = element.dataset.countryCode;
                    const countryName = element.querySelector('.iti__country-name');
                    countryName.innerText = countryCode;
                });
            });
        }, 1500);
        phoneInputs && phoneInputs.forEach(item => {
            item.addEventListener('input', event => {
                const targetEl = event.target;
                if (targetEl.value.match(/[^0-9]/g)) {
                    targetEl.value = targetEl.value.replace(/[^0-9]/g, "");
                }
            });
            const iti = window.intlTelInput(item, {
                separateDialCode: true,
                showFlags: false,
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
            });
            const countryCode = iti.getSelectedCountryData().iso2;
            const parent = item.closest('.iti--separate-dial-code');
            if (parent) {
                const flagWrapper = parent.querySelector('.iti__selected-flag');
                flagWrapper && flagWrapper.insertAdjacentHTML('afterbegin', `<div class="country-code">${countryCode}</div>`);
            }
            item.addEventListener("countrychange", function () {
                const countryCode = iti.getSelectedCountryData().iso2;
                const parent = item.closest('.iti--separate-dial-code');
                if (parent && countryCode) {
                    const flagWrapper = parent.querySelector('.iti__selected-flag');
                    const code = flagWrapper === null || flagWrapper === void 0 ? void 0 : flagWrapper.querySelector('.country-code');
                    if (code) {
                        code.innerText = countryCode;
                    }
                }
            });
        });
        openSubMenu && openSubMenu.forEach(item => {
            item.addEventListener('click', event => {
                event.preventDefault();
                const targetElement = event.target;
                const submenu = targetElement.nextElementSibling;
                submenu && submenu.classList.toggle('open');
                targetElement.classList.toggle('open');
            });
        });
        anchorLinkScroll('.js-anchor-button');
        if (header) {
            window.addEventListener('scroll', (event) => {
                if (window.scrollY >= 50) {
                    header.classList.add('sticky');
                }
                else {
                    header.classList.remove('sticky');
                }
            });
        }
        blogSearch && blogSearch.addEventListener('input', debounce((event) => {
            const search = event.target.value;
            const loadMore = document.querySelector('.js-blog-load-more');
            let offset = Number(loadMore.dataset.offset) - 6;
            const data = new FormData();
            data.append('action', 'load_posts');
            data.append('search', String(search));
            data.append('offset', '0');
            fetch(var_from_php.ajax_url, {
                method: "POST",
                credentials: 'same-origin',
                body: data
            })
                .then((response) => response.json())
                .then((responseData) => {
                if (!responseData.success)
                    return;
                const html = responseData.data.html;
                const countAllPost = Number(responseData.data.count);
                if (html) {
                    const wrapper = document.querySelector('.js-blog-wrap');
                    if (!wrapper)
                        return;
                    wrapper.innerHTML = html;
                    const countPosts = document.querySelectorAll('.js-news-item').length;
                    const dataOffset = offset + 6;
                    if (countPosts >= countAllPost) {
                        loadMore.style.display = 'none';
                    }
                    else {
                        loadMore.style.display = 'inline-block';
                    }
                    loadMore.dataset.offset = String(dataOffset);
                }
            });
        }));
        dummyLink && dummyLink.forEach(item => {
            item.addEventListener('click', event => {
                event.preventDefault();
            });
        });
        document.body.addEventListener('click', (event) => {
            const targetElem = event.target;
            const ROLE = targetElem.dataset.role;
            if (!ROLE)
                return;
            switch (ROLE) {
                case 'open-question':
                    {
                        event.preventDefault();
                        const parent = targetElem.closest('.js-faq-item');
                        parent && parent.classList.toggle('open');
                        targetElem.classList.toggle('open');
                    }
                    break;
                case 'load-news':
                    event.preventDefault();
                    const offset = Number(targetElem.dataset.offset);
                    const searchInput = document.querySelector('.js-blog-search');
                    const data = new FormData();
                    data.append('action', 'load_posts');
                    data.append('offset', String(offset));
                    data.append('search', searchInput.value);
                    fetch(var_from_php.ajax_url, {
                        method: "POST",
                        credentials: 'same-origin',
                        body: data
                    })
                        .then((response) => response.json())
                        .then((responseData) => {
                        if (!responseData.success)
                            return;
                        const html = responseData.data.html;
                        const countAllPost = Number(responseData.data.count);
                        if (html) {
                            const wrapper = document.querySelector('.js-blog-wrap');
                            if (!wrapper)
                                return;
                            wrapper.insertAdjacentHTML('beforeend', html);
                            const countPosts = document.querySelectorAll('.js-news-item').length;
                            const dataOffset = offset + 6;
                            if (countPosts >= countAllPost) {
                                targetElem.style.display = 'none';
                            }
                            else {
                                targetElem.style.display = 'inline-block';
                            }
                            targetElem.dataset.offset = String(dataOffset);
                        }
                    });
                    break;
                case 'service_price':
                    {
                        const parent = targetElem.closest('.js-service-item');
                        const priceTitle = parent === null || parent === void 0 ? void 0 : parent.querySelector('.js-price-title');
                        const priceNameInput = document.querySelector('#service_name');
                        const serviceNameInput = document.querySelector('#price_name');
                        const serviceName = targetElem.dataset.name;
                        if (priceTitle && priceNameInput) {
                            const priceName = priceTitle.innerText;
                            priceNameInput.value = priceName;
                        }
                        if (serviceNameInput && serviceName) {
                            serviceNameInput.value = serviceName;
                        }
                    }
                    break;
                case 'open-menu':
                    {
                        event.preventDefault();
                        const header = document.querySelector('#site-header');
                        const body = document.querySelector('body');
                        header && header.classList.toggle('menu-open');
                        body && body.classList.toggle('menu-open');
                        targetElem.classList.toggle('open');
                        const menu = document.querySelector('.js-mobile-menu');
                        menu && menu.classList.toggle('open');
                    }
                    break;
            }
        });
    }
    window.document.addEventListener('DOMContentLoaded', ready);
})(jQuery);
